:root {
  --react-pdf-annotation-layer: 1;
  --annotation-unfocused-field-background: url("data:image/svg+xml;charset=UTF-8,<svg width='1px' height='1px' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill:rgba(0, 54, 255, 0.13);'/></svg>");
  --input-focus-border-color: Highlight;
  --input-focus-outline: 1px solid Canvas;
  --input-unfocused-border-color: transparent;
  --input-disabled-border-color: transparent;
  --input-hover-border-color: black;
  --link-outline: none;
}

@media screen and (forced-colors: active) {
  :root {
    --input-focus-border-color: CanvasText;
    --input-unfocused-border-color: ActiveText;
    --input-disabled-border-color: GrayText;
    --input-hover-border-color: Highlight;
    --link-outline: 1.5px solid LinkText;
  }

  .annotationLayer .choiceWidgetAnnotation select:required {
    outline: 1.5px solid selecteditem;
  }

  .annotationLayer .textWidgetAnnotation :is(input, textarea):required {
    outline: 1.5px solid selecteditem;
  }

  .annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input:required {
    outline: 1.5px solid selecteditem;
  }

  .annotationLayer .linkAnnotation:hover {
    -webkit-backdrop-filter: invert();
    backdrop-filter: invert();
  }
}

.annotationLayer {
  pointer-events: none;
  transform-origin: 0 0;
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
}

.annotationLayer[data-main-rotation="90"] .norotate {
  transform: rotate(270deg)translateX(-100%);
}

.annotationLayer[data-main-rotation="180"] .norotate {
  transform: rotate(180deg)translate(-100%, -100%);
}

.annotationLayer[data-main-rotation="270"] .norotate {
  transform: rotate(90deg)translateY(-100%);
}

.annotationLayer canvas {
  width: 100%;
  height: 100%;
  position: absolute;
}

.annotationLayer section {
  text-align: initial;
  pointer-events: auto;
  box-sizing: border-box;
  transform-origin: 0 0;
  margin: 0;
  position: absolute;
}

.annotationLayer .linkAnnotation {
  outline: var(--link-outline);
}

.annotationLayer :is(.linkAnnotation, .buttonWidgetAnnotation.pushButton) > a {
  width: 100%;
  height: 100%;
  font-size: 1em;
  position: absolute;
  top: 0;
  left: 0;
}

.annotationLayer :is(.linkAnnotation, .buttonWidgetAnnotation.pushButton) > a:hover {
  opacity: .2;
  background: #ff0;
  box-shadow: 0 2px 10px #ff0;
}

.annotationLayer .textAnnotation img {
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.annotationLayer .choiceWidgetAnnotation select {
  background-image: var(--annotation-unfocused-field-background);
  border: 2px solid var(--input-unfocused-border-color);
  box-sizing: border-box;
  font: calc(9px * var(--scale-factor)) sans-serif;
  vertical-align: top;
  width: 100%;
  height: 100%;
  margin: 0;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea) {
  background-image: var(--annotation-unfocused-field-background);
  border: 2px solid var(--input-unfocused-border-color);
  box-sizing: border-box;
  font: calc(9px * var(--scale-factor)) sans-serif;
  vertical-align: top;
  width: 100%;
  height: 100%;
  margin: 0;
}

.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input {
  background-image: var(--annotation-unfocused-field-background);
  border: 2px solid var(--input-unfocused-border-color);
  box-sizing: border-box;
  font: calc(9px * var(--scale-factor)) sans-serif;
  vertical-align: top;
  width: 100%;
  height: 100%;
  margin: 0;
}

.annotationLayer .choiceWidgetAnnotation select:required {
  outline: 1.5px solid red;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea):required {
  outline: 1.5px solid red;
}

.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input:required {
  outline: 1.5px solid red;
}

.annotationLayer .choiceWidgetAnnotation select option {
  padding: 0;
}

.annotationLayer .buttonWidgetAnnotation.radioButton input {
  border-radius: 50%;
}

.annotationLayer .textWidgetAnnotation textarea {
  resize: none;
}

.annotationLayer .choiceWidgetAnnotation select[disabled] {
  border: 2px solid var(--input-disabled-border-color);
  cursor: not-allowed;
  background: none;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea)[disabled] {
  border: 2px solid var(--input-disabled-border-color);
  cursor: not-allowed;
  background: none;
}

.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input[disabled] {
  border: 2px solid var(--input-disabled-border-color);
  cursor: not-allowed;
  background: none;
}

.annotationLayer .choiceWidgetAnnotation select:hover {
  border: 2px solid var(--input-hover-border-color);
}

.annotationLayer .textWidgetAnnotation :is(input, textarea):hover {
  border: 2px solid var(--input-hover-border-color);
}

.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input:hover {
  border: 2px solid var(--input-hover-border-color);
}

.annotationLayer .choiceWidgetAnnotation select:hover, .annotationLayer .buttonWidgetAnnotation.checkBox input:hover {
  border-radius: 2px;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea):hover {
  border-radius: 2px;
}

.annotationLayer .choiceWidgetAnnotation select:focus {
  border: 2px solid var(--input-focus-border-color);
  outline: var(--input-focus-outline);
  background: none;
  border-radius: 2px;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea):focus {
  border: 2px solid var(--input-focus-border-color);
  outline: var(--input-focus-outline);
  background: none;
  border-radius: 2px;
}

.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) :focus {
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

.annotationLayer .buttonWidgetAnnotation.checkBox :focus {
  border: 2px solid var(--input-focus-border-color);
  outline: var(--input-focus-outline);
  border-radius: 2px;
}

.annotationLayer .buttonWidgetAnnotation.radioButton :focus {
  border: 2px solid var(--input-focus-border-color);
  outline: var(--input-focus-outline);
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:before, .annotationLayer .buttonWidgetAnnotation.checkBox input:checked:after, .annotationLayer .buttonWidgetAnnotation.radioButton input:checked:before {
  content: "";
  background-color: canvastext;
  display: block;
  position: absolute;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:before, .annotationLayer .buttonWidgetAnnotation.checkBox input:checked:after {
  width: 1px;
  height: 80%;
  left: 45%;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:before {
  transform: rotate(45deg);
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:after {
  transform: rotate(-45deg);
}

.annotationLayer .buttonWidgetAnnotation.radioButton input:checked:before {
  border-radius: 50%;
  width: 50%;
  height: 50%;
  top: 20%;
  left: 30%;
}

.annotationLayer .textWidgetAnnotation input.comb {
  padding-left: 2px;
  padding-right: 0;
  font-family: monospace;
}

.annotationLayer .textWidgetAnnotation input.comb:focus {
  width: 103%;
}

.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input {
  -webkit-appearance: none;
  appearance: none;
}

.annotationLayer .popupTriggerArea {
  width: 100%;
  height: 100%;
}

.annotationLayer .fileAttachmentAnnotation .popupTriggerArea {
  position: absolute;
}

.annotationLayer .popupWrapper {
  font-size: calc(9px * var(--scale-factor));
  min-width: calc(180px * var(--scale-factor));
  pointer-events: none;
  width: 100%;
  position: absolute;
}

.annotationLayer .popup {
  max-width: calc(180px * var(--scale-factor));
  box-shadow: 0 calc(2px * var(--scale-factor)) calc(5px * var(--scale-factor)) #888;
  border-radius: calc(2px * var(--scale-factor));
  padding: calc(6px * var(--scale-factor));
  margin-left: calc(5px * var(--scale-factor));
  cursor: pointer;
  font: message-box;
  white-space: normal;
  word-wrap: break-word;
  pointer-events: auto;
  background-color: #ff9;
  position: absolute;
}

.annotationLayer .popup > * {
  font-size: calc(9px * var(--scale-factor));
}

.annotationLayer .popup h1 {
  display: inline-block;
}

.annotationLayer .popupDate {
  margin-left: calc(5px * var(--scale-factor));
  display: inline-block;
}

.annotationLayer .popupContent {
  margin-top: calc(2px * var(--scale-factor));
  padding-top: calc(2px * var(--scale-factor));
  border-top: 1px solid #333;
}

.annotationLayer .richText > * {
  white-space: pre-wrap;
  font-size: calc(9px * var(--scale-factor));
}

.annotationLayer .highlightAnnotation, .annotationLayer .underlineAnnotation, .annotationLayer .squigglyAnnotation, .annotationLayer .strikeoutAnnotation, .annotationLayer .freeTextAnnotation, .annotationLayer .lineAnnotation svg line, .annotationLayer .squareAnnotation svg rect, .annotationLayer .circleAnnotation svg ellipse, .annotationLayer .polylineAnnotation svg polyline, .annotationLayer .polygonAnnotation svg polygon, .annotationLayer .caretAnnotation, .annotationLayer .inkAnnotation svg polyline, .annotationLayer .stampAnnotation, .annotationLayer .fileAttachmentAnnotation {
  cursor: pointer;
}

.annotationLayer section svg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.annotationLayer .annotationTextContent {
  opacity: 0;
  color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
}

.annotationLayer .annotationTextContent span {
  width: 100%;
  display: inline-block;
}

:root {
  --react-pdf-text-layer: 1;
  --highlight-bg-color: #b400aa;
  --highlight-selected-bg-color: #006400;
}

@media screen and (forced-colors: active) {
  :root {
    --highlight-bg-color: Highlight;
    --highlight-selected-bg-color: ButtonText;
  }
}

[data-main-rotation="90"] {
  transform: rotate(90deg)translateY(-100%);
}

[data-main-rotation="180"] {
  transform: rotate(180deg)translate(-100%, -100%);
}

[data-main-rotation="270"] {
  transform: rotate(270deg)translateX(-100%);
}

.textLayer {
  text-align: initial;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  forced-color-adjust: none;
  transform-origin: 0 0;
  z-index: 2;
  line-height: 1;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.textLayer :is(span, br) {
  color: rgba(0, 0, 0, 0);
  white-space: pre;
  cursor: text;
  transform-origin: 0 0;
  margin: 0;
  position: absolute;
}

.textLayer span.markedContent {
  height: 0;
  top: 0;
}

.textLayer .highlight {
  background-color: var(--highlight-bg-color);
  border-radius: 4px;
  margin: -1px;
  padding: 1px;
}

.textLayer .highlight.appended {
  position: initial;
}

.textLayer .highlight.begin {
  border-radius: 4px 0 0 4px;
}

.textLayer .highlight.end {
  border-radius: 0 4px 4px 0;
}

.textLayer .highlight.middle {
  border-radius: 0;
}

.textLayer .highlight.selected {
  background-color: var(--highlight-selected-bg-color);
}

.textLayer br::selection {
  background: none;
}

.textLayer .endOfContent {
  z-index: -1;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  display: block;
  position: absolute;
  inset: 100% 0 0;
}

.textLayer .endOfContent.active {
  top: 0;
}

.CircularProgressbar {
  vertical-align: middle;
  width: 100%;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #3e98c7;
  stroke-linecap: round;
  transition: stroke-dashoffset .5s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6;
  stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #3e98c7;
  dominant-baseline: middle;
  text-anchor: middle;
  font-size: 20px;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: #d6d6d6;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
  fill: #3e98c7;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: rgba(0, 0, 0, 0);
}

@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("exo-2-cyrillic-ext-300-normal.f6d06157.woff2") format("woff2"), url("exo-2-cyrillic-ext-300-normal.8a29e2db.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("exo-2-cyrillic-300-normal.c739e24c.woff2") format("woff2"), url("exo-2-cyrillic-300-normal.bcae6cf5.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("exo-2-vietnamese-300-normal.6c3ccba6.woff2") format("woff2"), url("exo-2-vietnamese-300-normal.d257258d.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("exo-2-latin-ext-300-normal.c1bfde94.woff2") format("woff2"), url("exo-2-latin-ext-300-normal.6abeec5d.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("exo-2-latin-300-normal.a29d4221.woff2") format("woff2"), url("exo-2-latin-300-normal.7d428a35.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("exo-2-cyrillic-ext-400-normal.36c8f067.woff2") format("woff2"), url("exo-2-cyrillic-ext-400-normal.8fe0ff20.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("exo-2-cyrillic-400-normal.db078b24.woff2") format("woff2"), url("exo-2-cyrillic-400-normal.088cd6be.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("exo-2-vietnamese-400-normal.25567207.woff2") format("woff2"), url("exo-2-vietnamese-400-normal.5daf5b3a.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("exo-2-latin-ext-400-normal.841cc041.woff2") format("woff2"), url("exo-2-latin-ext-400-normal.b7b28cb3.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("exo-2-latin-400-normal.7520a2d4.woff2") format("woff2"), url("exo-2-latin-400-normal.8d50f6bf.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("exo-2-cyrillic-ext-500-normal.93dfaec1.woff2") format("woff2"), url("exo-2-cyrillic-ext-500-normal.1ef7e63a.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("exo-2-cyrillic-500-normal.f66c8f37.woff2") format("woff2"), url("exo-2-cyrillic-500-normal.62eaff2c.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("exo-2-vietnamese-500-normal.026ccd2e.woff2") format("woff2"), url("exo-2-vietnamese-500-normal.41a64094.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("exo-2-latin-ext-500-normal.dce75d0b.woff2") format("woff2"), url("exo-2-latin-ext-500-normal.7bb9d477.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("exo-2-latin-500-normal.806913eb.woff2") format("woff2"), url("exo-2-latin-500-normal.a28f4174.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("exo-2-cyrillic-ext-600-normal.96df2ebb.woff2") format("woff2"), url("exo-2-cyrillic-ext-600-normal.43fbb189.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("exo-2-cyrillic-600-normal.fc7e0d93.woff2") format("woff2"), url("exo-2-cyrillic-600-normal.e5f72488.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("exo-2-vietnamese-600-normal.3d7f5eb3.woff2") format("woff2"), url("exo-2-vietnamese-600-normal.5068bda4.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("exo-2-latin-ext-600-normal.44e89ecc.woff2") format("woff2"), url("exo-2-latin-ext-600-normal.becb6b81.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("exo-2-latin-600-normal.6a64a943.woff2") format("woff2"), url("exo-2-latin-600-normal.849d4ed5.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("exo-2-cyrillic-ext-700-normal.17181abb.woff2") format("woff2"), url("exo-2-cyrillic-ext-700-normal.12c3013d.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("exo-2-cyrillic-700-normal.66d82562.woff2") format("woff2"), url("exo-2-cyrillic-700-normal.de1fe3b1.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("exo-2-vietnamese-700-normal.75a6feee.woff2") format("woff2"), url("exo-2-vietnamese-700-normal.ff610367.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("exo-2-latin-ext-700-normal.54fa14ad.woff2") format("woff2"), url("exo-2-latin-ext-700-normal.4c6c266e.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Exo 2";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("exo-2-latin-700-normal.f3493aa3.woff2") format("woff2"), url("exo-2-latin-700-normal.41d05847.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-cyrillic-ext-300-normal.9acd4ce4.woff2") format("woff2"), url("roboto-cyrillic-ext-300-normal.45d32fa6.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-cyrillic-300-normal.c467fff6.woff2") format("woff2"), url("roboto-cyrillic-300-normal.590d6324.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-greek-ext-300-normal.0d946183.woff2") format("woff2"), url("roboto-greek-ext-300-normal.635e7419.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-greek-300-normal.8490af52.woff2") format("woff2"), url("roboto-greek-300-normal.5dbcee80.woff") format("woff");
  unicode-range: U+370-377, U+37A-37F, U+384-38A, U+38C, U+38E-3A1, U+3A3-3FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-vietnamese-300-normal.c7381d14.woff2") format("woff2"), url("roboto-vietnamese-300-normal.1d62abf0.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-latin-ext-300-normal.60772506.woff2") format("woff2"), url("roboto-latin-ext-300-normal.26578ec7.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-latin-300-normal.9224048c.woff2") format("woff2"), url("roboto-latin-300-normal.63bae07a.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-cyrillic-ext-400-normal.76be11d7.woff2") format("woff2"), url("roboto-cyrillic-ext-400-normal.939c6437.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-cyrillic-400-normal.cbec12de.woff2") format("woff2"), url("roboto-cyrillic-400-normal.51207a89.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-greek-ext-400-normal.b7f04734.woff2") format("woff2"), url("roboto-greek-ext-400-normal.ad4cea6a.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-greek-400-normal.814df5f0.woff2") format("woff2"), url("roboto-greek-400-normal.f7a9a8dc.woff") format("woff");
  unicode-range: U+370-377, U+37A-37F, U+384-38A, U+38C, U+38E-3A1, U+3A3-3FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-vietnamese-400-normal.74daec8c.woff2") format("woff2"), url("roboto-vietnamese-400-normal.b29add20.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-latin-ext-400-normal.bf7055a6.woff2") format("woff2"), url("roboto-latin-ext-400-normal.1ea12414.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-latin-400-normal.850ff351.woff2") format("woff2"), url("roboto-latin-400-normal.2b5b6b81.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-cyrillic-ext-500-normal.d969b788.woff2") format("woff2"), url("roboto-cyrillic-ext-500-normal.98451348.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-cyrillic-500-normal.b20bfae0.woff2") format("woff2"), url("roboto-cyrillic-500-normal.b30bef08.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-greek-ext-500-normal.4596dbe7.woff2") format("woff2"), url("roboto-greek-ext-500-normal.e606d292.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-greek-500-normal.8a9ec315.woff2") format("woff2"), url("roboto-greek-500-normal.69182d98.woff") format("woff");
  unicode-range: U+370-377, U+37A-37F, U+384-38A, U+38C, U+38E-3A1, U+3A3-3FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-vietnamese-500-normal.a8e35b0a.woff2") format("woff2"), url("roboto-vietnamese-500-normal.7c495b43.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-latin-ext-500-normal.507b47fa.woff2") format("woff2"), url("roboto-latin-ext-500-normal.5e87b8d1.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-latin-500-normal.d53ad09f.woff2") format("woff2"), url("roboto-latin-500-normal.1f1fc065.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-cyrillic-ext-700-normal.3e5b2674.woff2") format("woff2"), url("roboto-cyrillic-ext-700-normal.64c4b71f.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-cyrillic-700-normal.2aacf52f.woff2") format("woff2"), url("roboto-cyrillic-700-normal.28bb530d.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-greek-ext-700-normal.9d04b0df.woff2") format("woff2"), url("roboto-greek-ext-700-normal.49166884.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-greek-700-normal.ceff22e7.woff2") format("woff2"), url("roboto-greek-700-normal.16dbc4e4.woff") format("woff");
  unicode-range: U+370-377, U+37A-37F, U+384-38A, U+38C, U+38E-3A1, U+3A3-3FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-vietnamese-700-normal.fa5027dc.woff2") format("woff2"), url("roboto-vietnamese-700-normal.e17524a5.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-latin-ext-700-normal.9fb6cb67.woff2") format("woff2"), url("roboto-latin-ext-700-normal.3b64a761.woff") format("woff");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-latin-700-normal.d4eee69a.woff2") format("woff2"), url("roboto-latin-700-normal.6f0902d8.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/*# sourceMappingURL=index.05233ddd.css.map */
